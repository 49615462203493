import { template as template_8f580420467143c0b63352f41e4963af } from "@ember/template-compiler";
import { eq } from "truth-helpers";
const FKControlConditionalContentItem = template_8f580420467143c0b63352f41e4963af(`
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
