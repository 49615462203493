import { template as template_3df834d2e6c34e6b84dba3d2fb8fd801 } from "@ember/template-compiler";
const FKLabel = template_3df834d2e6c34e6b84dba3d2fb8fd801(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
